.navbar {
  background-color: #f8f9fa;
  padding: 10px;
  font-size: 1.5em;
  text-align: center;
}

.navbar a {
  margin: 0 10px;
  text-decoration: none;
  color: #333;
}

.navbar a:hover {
  color: purple;
}